import $ from 'jquery';
import AOS from './aos';

const urlGoogleSheetPOST = "https://script.google.com/macros/s/AKfycbydY1d_qPU28HZ860kmHU74PBpXeVRacRXBxaISv1AtsHlnCxuIx_dZUAzklyNqby5O/exec"
const sheetId = "16dbaO8yseMNiX9Cs7smC1qE2G4d4SD7Pd6pPE0J_zgU";
const sheetName = encodeURIComponent("Data");
const sheetURL = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;

getData()
countdownTimer()

function getData() {
    fetch(sheetURL)
        .then((response) => response.text())
        .then((csvText) => handleResponse(csvText));
}

function main(needConfirm) {
    setTimeout(() => {
        $(".logo").addClass("hide")
        setTimeout(() => {
            $(".logo").css("display", "none")
            if (needConfirm) {
                $(".question").css("display", "initial")
                setTimeout(() => {
                    $(".question").addClass("show")
                }, 200)
            } else {
                showMainWebsite()
            }
        }, 1000)
    }, 1000)
}

$(".select").click(function () {
    $(".select").removeClass("selected")
    $(this).addClass("selected")
    let value = $(this).attr("data-select");
    if (value === "0") {
        $(".confirm").removeClass("disabled")
        $(".number").addClass("disabled").removeClass("selected")
    } else {
        $(".confirm").addClass("disabled")
        $(".number").removeClass("disabled")
    }
    $("#confirm").val(value)
})

$(".number").click(function () {
    $(".number").removeClass("selected")
    $(this).addClass("selected")
    $(".confirm").removeClass("disabled")
    let value = $(this).attr("data-select");
    $("#count").val(value)
})

$(".confirm").click(function (event) {
    $("#form").attr("action", urlGoogleSheetPOST).submit();
    showMainWebsite()
})

function showMainWebsite() {
    setTimeout(() => {
        $(".loading").addClass("hide")
        setTimeout(() => {
            $(".loading").css("display", "none")
            $("body").css("overflow-y", "visible")
            AOS.init();
        }, 1000)
    }, 200)
}

function handleResponse(csvText) {
    let sheetObjects = csvToObjects(csvText);
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id')
    $("#id").val(id)
    let needConfirm = false
    sheetObjects.forEach(target => {
        if (target.id === id) {
            $("#name").text(target.name)
            if (target.confirm === "") {
                needConfirm = true
            }
        }
    })
    main(needConfirm)
}

function csvToObjects(csv) {
    const csvRows = csv.split("\n");
    const propertyNames = csvSplit(csvRows[0]);
    let objects = [];
    for (let i = 1, max = csvRows.length; i < max; i++) {
        let thisObject = {};
        let row = csvSplit(csvRows[i]);
        for (let j = 0, max = row.length; j < max; j++) {
            thisObject[propertyNames[j]] = row[j];
        }
        objects.push(thisObject);
    }
    return objects;
}

function csvSplit(row) {
    return row.split(",").map((val) => val.substring(1, val.length - 1));
}

function countdownTimer() {
    let countDownDate = new Date("Oct 19, 2024 17:00:00").getTime();
    let day = $("#day")
    let hour = $("#hour")
    let minute = $("#minute")
    let second = $("#second")

    let x = setInterval(function() {

        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        day.text(Math.floor(distance / (1000 * 60 * 60 * 24)))
        hour.text(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
        minute.text(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
        second.text(Math.floor((distance % (1000 * 60)) / 1000))

        // If the count down is over, write some text
        if (distance < 0) {
            clearInterval(x);
            day.text(0)
            hour.text(0)
            minute.text(0)
            second.text(0)
        }
    }, 1000);
}